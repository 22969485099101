import request from '@/plugins/axios';
/* ----------【all】分页车辆------------ */
export const getTypeCode = (data) => {
  return request.post(`/resource/type/code/list`, data).then((res) => {
    return res;
  });
};
export const addTypeCode = (data) => {
  return request.post('/resource/type/code/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// export const getDetailTypeCode = (data) => {
//   return request.get('/resource/type/code/detail', data).then((res) => {
//     return res;
//   });
// };
export const editTypeCode = (data) => {
  return request.post('/resource/type/code/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteTypeCode = (data) => {
  return request.post('/resource/type/code/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteTypeCode = (data) => {
  return request.post('/resource/type/code/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};

