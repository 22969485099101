<template>
  <div class="label-list-page-wrap">
    <div v-if="!showInput" class="add-btn" @click="showInput=!showInput">
      <i class="iconfont el-icon-plus" />
      <span>添加标签</span>
    </div>
    <div v-else>
      <el-form :model="form" size="mini" @submit.native.prevent>
        <el-form-item label="" prop="resValue" style="width: 200px;">
          <el-input v-model="form.resValue" :placeholder="`${resTypeDesc}(请输入后回车提交)`" clearable maxlength="30" @keyup.enter.native="submit" />
        </el-form-item>
      </el-form>
    </div>
    <div class="label-list-wrap" :class="{'allow-del':type==='edit'}">
      <div
        v-for="(item, index) in labelData"
        :key="index"
        class="label-wrap"
        :class="{'active':item.active}"
        @click="selectLabel(item,index)"
      >
        {{ item.resValue }}
        <i v-if="type==='edit'" class="el-icon-close" @click="deleteLabel(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import { getTypeCode, addTypeCode, batchDeleteTypeCode } from '@/api/typeCode';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default() {
        return 'normal';
      },
    },
    initLabelData: {
      type: Array,
      default() {
        return [];
      },
    },
    resType: {
      type: [String, Number],
      default() {
        return 7;
      },
    },
    resTypeDesc: {
      type: String,
      default() {
        return '产品标签';
      },
    },
  },
  data() {
    return {
      showInput: false,
      form: {},
      labelData: [],
      selectLabelList: [],
    };
  },

  computed: {},

  watch: {
    initLabelData(val) {
      if (!this.labelData.length) {
        return false;
      }
      const newInit = this.labelData.filter((item) => val.includes(item.resValue));
      // item.resValue ||item.resCode
      this.selectLabelList = newInit;
      if (this.type === 'normal') {
        const updatedA = this.labelData.map((item) => {
          item.active = false;
          const matchedB = this.selectLabelList.find((bItem) => bItem.id === item.id);
          if (matchedB) {
            return { ...item, active: true };
          }
          return item;
        });
        this.labelData = updatedA;
        this.$emit('selectLabel', this.selectLabelList);
      }
    },
  },

  created() {
    // this.getLabelData();
  },
  methods: {
    // 获取标签数组
    getLabelData() {
      const params = {
        resType: this.resType,
      };
      getTypeCode(params).then(res => {
        if (res) {
          res.forEach(e => {
            e.active = false;
          });
        }
        this.labelData = res || [];
      }).finally(() => {
        if (this.selectLabelList.length && this.type === 'normal') {
          const updatedA = this.labelData.map((item) => {
            const matchedB = this.selectLabelList.find((bItem) => bItem.id === item.id);
            if (matchedB) {
              return { ...item, active: true };
            }
            return item;
          });
          this.labelData = updatedA;
        }
      });
    },
    addLabelData() {
      const uniqueId = new Date().getTime();
      const params = {
        resType: this.resType, // 运力6产品7
        resCode: uniqueId,
        resValue: this.form.resValue, // 标签名
      };
      addTypeCode(params).then(res => {
        if (res) {
          this.$baseMessage('新增标签成功！', 'success');
          this.getLabelData();
          this.form.resValue = '';
          this.showInput = false;
        }
      });
    },
    add() {
      this.showInput = true;
    },
    submit() {
      if (!this.form.resValue) {
        this.$baseMessage('标签内容不能为空！', 'error');
        return;
      }
      if (this.labelData.filter(d => d.resValue === this.form.resValue).length) {
        this.$baseMessage('标签内容已存在，请重新输入！', 'error');
        return;
      }
      this.addLabelData();
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      // });
    },
    selectLabel(e, i) {
      this.labelData[i].active = !this.labelData[i].active;
      // this.selectLabelList = this.labelData.filter(d => d.active);
      if (e.active) {
        this.selectLabelList.push(e);
      } else {
        const updatedSelectLabelList = this.selectLabelList.filter((item) => item.id !== e.id);
        this.selectLabelList = updatedSelectLabelList;
      }
      this.$emit('selectLabel', this.selectLabelList);
    },
    deleteLabel(data) {
      const params = {
        ids: [data.id] || null,
      };
      batchDeleteTypeCode(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.selectLabelList = [];
        this.getLabelData();
      });
    },
  },
};

</script>
<style lang='scss' scoped>
.label-list-page-wrap{
  padding: 20px;
  .add-btn{
    // height: 29px;
    border: 1px dashed #ccc;
    width: 90px;
    padding: 3px 5px;
    text-align: center;
    font-size: 12px;
    color: #686868;
    cursor: pointer;
    transition: 0.3s;
    i{
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .add-btn:hover{
    border: 1px dashed $blue;
    color: $blue;
  }
  .label-list-wrap{
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    .label-wrap{
      margin: 0 6px 6px 0;
      padding: 4px 6px;
      background: #E7E7E7;
      border-radius: 3px;
      font-size: 12px;
      cursor: pointer;
      // transition: 0.1s;
      .el-icon-close {
        font-size: 12px;
        color: #58595c;
      }
    }
    .label-wrap:hover{
      color: $blue;
    }
    .active{
      background: $blue;
      color: #fff;
    }
    .active:hover{
      color: #fff;
    }
    // .active,.allow-del{
    //   color: #606266;
    //   background: #E7E7E7;
    // }
    // .allow-del:hover{
    //   color: #606266;
    // }
  }
  .allow-del{
    .label-wrap:hover{
      color: #606266;
      .el-icon-close{
        color: red;
      }
    }
    .active{
      background: #E7E7E7;
      color: #606266;
    }
    .active:hover{
      color: #606266;
    }
  }
}
</style>
